// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {
    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                "Msxml2.XMLHTTP.6.0",
                "Msxml2.XMLHTTP.3.0",
                "Msxml2.XMLHTTP",
                "Microsoft.XMLHTTP",
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {
                }
            }
        } else {
            xhr = new XMLHttpRequest();
        }
    } else {
        return null;
    }
    return xhr;
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
    );
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseXML;
    else return false;
}

var global_id_transporteur = '';

function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {
    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    var divId = 'popup_numero_retour';
    divId += '_' + (multiTransp ? idOrderTransp : idOrder);
    var formId = '#return_products_form';
    formId += '_' + (multiTransp ? idOrderTransp : idOrder);

    $(formId).find('.holder_wrapper').overlayScrollbars({});
    openMultiShad(divId);
    $('#shad').addClass('actif');
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {
    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    if (!show_pdf) {
        data = $('#return_products_form_' + idCmdOrder).serialize();
        data += '&idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder=' + idCmdOrder + '&multiTransp=' + multiTransp + '&return_number=' + numRetour;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_create_etiquette'),
        type: 'post',
        data: data,
        success: function (res) {
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {
    var numRetour = "";

    if (typeof idOrderTransp != "undefined")
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    else
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );

    if (numRetour != "false") {
        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";
        var divCreateNumRetourObjet = document.getElementById("createNumRetour" + transporteur);
        var divPrintPdfObjet = document.getElementById("printPdf" + transporteur);

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function () {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0 && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }
            showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, false, idOrderTransp);
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }

    }
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                parentPath +
                '../svg/loader.svg" style="margin-bottom:-6px;margin-right:10px; width:40px;" /></td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            path_relative_root +
            "ajax_liste_qte.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            0 +
            "&idtaille=" +
            0 +
            "&produit_lot_nb=" +
            $("#produit_lot_nb").val() +
            "&suff=" +
            (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function updatePanier(idProduit, i, paniervirtuelId) {
    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var qte = document.getElementById("qteProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    ajax_file(
        "ajax/ajax_upd_panier.php?idprod=" +
        idProduit +
        "&idcouleur=" +
        idCouleur +
        "&idtaille=" +
        idTaille +
        "&qte=" +
        qte.value +
        "&panierId=" +
        paniervirtuelId
    );
}

function newUpdatePanier(
    idProduit,
    i,
    paniervirtuelId,
    prixU,
    path_web,
    titreObjet
) {
    if (i != "") {
        i = "_" + i;

        prixU = $("#prixU" + i).val();
    }

    var res = "";
    var html_err = "";
    var flagError = false;

    if ($("#lightbox_achat_express").length > 0) {
        from_range = true;
    } else {
        from_range = false;
    }

    html_err = '<div class="txt_alert"></div><div class="rosette"></div>';
    html_err +=
        '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

   if (
        document.getElementById("qteProd" + i) &&
        document.getElementById("qteProd" + i).value == ""
    ) {
        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {
        alert(res);

        return false;
    } else {

        var qte = 0;

        if ($("#qteProd" + i)) {
            qte = $("#qteProd" + i).val();
        }

        var idColor = 0;
        var idSize = 0;

        if ($("#isGift").data('product_add_basket_id') && $("#isGift").data('product_add_basket_id') == idProduit) {
            idSize = $("#isGift").data('product_add_basket_size');
            idColor = $("#isGift").data('product_add_basket_color');
        }

        var array_response = [];
        var response = ajax_file(
            path_web +
            "ajax_upd_panier.php?prixu=" +
            prixU +
            "&idprod=" +
            idProduit +
            "&idcouleur=" +
            idColor +
            "&idtaille=" +
            idSize +
            "&qte=" +
            qte +
            "&panierId=" +
            paniervirtuelId
        );

        if (response) {
            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match("^[0-9]")) {
                if (designation == 1) {
                    designation += " lot de";
                } else {
                    designation += " lots de";
                }
            }

            designation += " " + titreObjet;
            array_response[0] = designation;
        }

        return array_response;
    }
}

//Affiche la liste des questions appropri�es au service demand�
function show_question(elementID, fieldID, idService, parentPath) {
    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {
        $.ajax({
            url: path_relative_root + "ajax_list_question.php",
            type: "post",
            data: {idService: idService},
            success: function (res) {
                list_question.innerHTML = res;
                field.innerHTML = "";
            },
        });
    }
}

// Affiche les champs appropri�es au service et � la question demand�
function show_fields(elementID, idService, idQuestion, parentPath) {
    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {
        var response = ajax_file(
            parentPath +
            "ajax_field_contact.php?idService=" +
            idService +
            "&idQuestion=" +
            idQuestion
        );

        $(".contact-beware a").attr(
            "href",
            parentPath +
            create_link("connexion_login") +
            "?from=contact&id_service=" +
            idService +
            "&id_question=" +
            idQuestion
        );

        if (response) {
            list_field.innerHTML = response;

            $("#produit_ref_input").bind("keyup", function () {
                // Recuperation de tous les produit
                getAllProduct("produit_ref");
            });

            $("#produit_nom_input").bind("keyup", function () {
                // Recuperation de tous les produit
                getAllProduct("produit_nom");
            });

            tabError = [];

            tabError[tabError.length] = [
                "radio",
                "optin",
                "message",
            ];

            tabError[tabError.length] = [
                "alpha",
                "contactNom",
                "contact_field_nom",
            ];
            tabError[tabError.length] = [
                "alpha",
                "contactPrenom",
                "contact_field_prenom",
            ];
            tabError[tabError.length] = [
                "mail",
                "contactMail",
                "contact_field_mail",
            ];

            if (idService == 7) {
                tabError[tabError.length] = [
                    "alphanum",
                    "contactAdresse",
                    "contact_field_adresse",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactCP",
                    "contact_field_cp",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactVille",
                    "contact_field_ville",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactPays",
                    "contact_field_pays",
                ];
            } else {
                if (idService == 2 && idQuestion == 5) {
                    tabError[tabError.length] = [
                        "alphanum",
                        "contactRefProduit",
                        "contact_field_ref_produit",
                    ];
                }

                if (idService == 14) {
                    if (idQuestion == 3) {
                        tabError[tabError.length] = [
                            "alphanumadresse",
                            "contactAdresse",
                            "contact_field_adresse",
                        ];
                        tabError[tabError.length] = [
                            "phone",
                            "contactNumPortable",
                            "contact_field_num_portable",
                        ];
                        tabError[tabError.length] = [
                            "alphanum",
                            "contactCP",
                            "contact_field_cp",
                        ];
                        tabError[tabError.length] = [
                            "alphanumadresse",
                            "contactVille",
                            "contact_field_ville",
                        ];
                        tabError[tabError.length] = [
                            "select_date",
                            "garantieDateAchat",
                            "date_achat",
                        ];
                        tabError[tabError.length] = [
                            "prix",
                            "contactPrix",
                            "prix",
                        ];
                        tabError[tabError.length] = [
                            "alphanum",
                            "id_produit_ref",
                            "date_achat",
                        ];

                        // Date Achat validation
                        $("#btn_contact").click(function (e) {
                            e.preventDefault();

                            if (
                                document.getElementById("garantieDateAchatM")
                                    .value === "" ||
                                document.getElementById("garantieDateAchatA")
                                    .value === "" ||
                                document.getElementById("garantieDateAchatJ")
                                    .value === ""
                            ) {
                                $("#garantieDateAchatLabel").addClass(
                                    "labelErr"
                                );
                            } else {
                                $("#garantieDateAchatLabel").removeClass(
                                    "labelErr"
                                );
                                $("#garantieDateAchatLabel")
                                    .siblings(".img_error")
                                    .remove();
                            }

                            if ($("#id_produit_ref").val() == "") {
                                $("#produit_ref_input").attr(
                                    "class",
                                    "inputErr"
                                );
                                $("#produit_nom_input").attr(
                                    "class",
                                    "inputErr"
                                );
                                $("#id_produit_refLabel").attr(
                                    "class",
                                    "labelErr"
                                );
                                $("#produit_nom_inputLabel").attr(
                                    "class",
                                    "labelErr"
                                );

                                $("#id_produit_ref").after(
                                    '<img style="margin: 2px 0 0 10px" src="/wshop-front/gap-france.fr/htdocs/img/picto_panneau.png" alt="" class="img_error">'
                                );
                                $("#produit_nom_input").after(
                                    '<img style="margin: 2px 0 0 10px" src="/wshop-front/gap-france.fr/htdocs/img/picto_panneau.png" alt="" class="img_error">'
                                );
                            } else {
                                $("#produit_ref_input").attr(
                                    "class",
                                    "inputForm"
                                );
                                $("#produit_nom_input").attr(
                                    "class",
                                    "inputForm"
                                );
                                $("#id_produit_refLabel").attr("class", "");
                                $("#produit_nom_inputLabel").attr("class", "");
                            }

                            if ($("#contactMagasin").val() == "") {
                                $("#contactMagasin").after(
                                    '<img style="margin: 2px 0 0 10px" src="/wshop-front/gap-france.fr/htdocs/img/picto_panneau.png" alt="" class="img_error">'
                                );
                                $("#contactMagasinLabel").addClass("labelErr");
                            }
                        });
                    } else if (idQuestion == 2) {
                        var content = ajax_file(
                            "ajax_field_active_garantie.php"
                        );

                        if (content) {
                            $("#select_garantieNomProduit").append(content);
                            $("#field_garantieNomProduit").removeClass("cache");
                            $("#field_error_garantieProduit").addClass("cache");
                            $("#field_photo").addClass("cache");
                            $("#field_error_garantieProduit td").empty();
                        } else {
                            $("#field_garantieNomProduit").addClass("cache");
                        }
                    }
                }
            }

            if (!(idService == 3 && idQuestion == 2) && idService != 7) {
                tabError[tabError.length] = [
                    "no_empty",
                    "contactMessage",
                    "contact_field_message",
                ];
            }
        } else {
            list_field.innerHTML = "";
        }
    }
}

function show_field_form(parentPath) {
    var list_field = document.getElementById("field");
    clearTimeout(time_out);
    if (list_field) {
        var response = ajax_file(
            parentPath + "ajax_field_contact.php?idService=7&idQuestion=1"
        );
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array("alpha", "contactNom", "contact_field_nom");
            tabError[1] = new Array(
                "alpha",
                "contactPrenom",
                "contact_field_prenom"
            );
            tabError[2] = new Array(
                "mail",
                "contactMail",
                "contact_field_mail"
            );
            tabError[3] = new Array(
                "alphanum",
                "contactAdresse",
                "contact_field_adresse"
            );
            tabError[4] = new Array(
                "alphanum",
                "contactCP",
                "contact_field_cp"
            );
            tabError[5] = new Array(
                "alphanum",
                "contactVille",
                "contact_field_ville"
            );
            tabError[6] = new Array(
                "alphanum",
                "contactPays",
                "contact_field_pays"
            );
        } else list_field.innerHTML = "";
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {

        generateNewBlocSearch(page_bloc);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    }
}

function paginate(page) {

    if (document.getElementById('filter_sticky')) {
        var form = document.getElementById('filter_sticky'),
            total_element = form.getValue('totalElems', parseFloat),
            nb_prod = form.getValue('nb_prod', parseFloat),
            nb_total_page = Math.ceil(total_element / nb_prod),
            pagination = $('.pager_wrapper'),
            nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        see_all = form.getValue('see_all', parseFloat);

        page = page || 1;

        $.ajax({
            url: path_relative_root + create_link('ajax_reload_pagination'),
            type: 'get',
            data: {
                page: page,
                link: 'paginationGoToPage()',
                nb_total_page: nb_total_page
            },
            success: function (res) {

                var productLoaded = $('.item.swiper-slidexs').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                pagination.each(function () {

                    if (nb_prod !== 1000 && nb_prod < total_element) {
                        this.innerHTML = res + '<div class="nbProdDisplayed">' + productLoaded + ' ' + Translator.translate('pagerTxt') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product" onclick="seeXProduct(1000)">' + Translator.translate('see_more_products') + '</div>';
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="nbProdDisplayed">' + productLoaded + ' ' + Translator.translate('pagerTxt') + ' ' + total_element + '</div><div class="loadedGauge"><span style="width: ' + percentLoaded + '%;"></span></div><div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ')">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                    } else {
                        this.innerHTML = '';
                    }
                });
            }
        });
    }
}

function seeXProduct(nb_product) {
    // On definie le nombre de produit a charger à 1000
    $('#nb_prod').val(nb_product);
    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(0);
    } else {
        generateNewBlocProd(0);
    }
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollTop, fromScrollBottom) {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {};

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');

    if (page === 'all') {
        /** LEGACY **/

        var hash;

        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0]) || page_value > 0) {

            var marginTop = $('.banner_wrapper').height() + 20,
                $elem = hash ? $('#' + hash.replace('=', '')) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {

                $('#contents_wrapper').animate({scrollTop: top}, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {

        if (nb_product === 'all') {

            $('.see_all').html(
                '<img src="' + path_relative_root + 'img/loader.gif" width="20" height="20" />'
            );

        }

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link('ajax_reload_products'),
            type: 'get',
            data: output,
            success: function (res) {

                var old_elm = document.getElementById('list_item'),
                    content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');

                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                // This block of code updates the SEO-related link elements in the HTML document.
                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8])[1].en);
                $("link[rel^='alternate'][hreflang^='de']").attr("href", JSON.parse(res[8])[1].de);
                $("link[rel^='alternate'][hreflang^='nl']").attr("href", JSON.parse(res[8])[1].nl);
                $("link[rel^='alternate'][hreflang^='en-gb']").attr("href", JSON.parse(res[8])[2].en);
                $("link[rel^='alternate'][hreflang^='x-default']").attr("href", JSON.parse(res[8])[1].en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".autoLoad").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    paginate(page + 1);
                    old_elm.insertAdjacentHTML('beforeend', content);

                    if (!isNaN(page)) {

                        if (page == 0 && $(".img_series_special").length > 0) {
                            $("#list_item").addClass('series_special');
                            var elem_img = $(".img_series_special").clone();
                            elem_img.show();
                            $("#list_item").prepend(elem_img);
                        }

                    }

                    if (window.lazyload !== undefined) {

                        lazyload.init();
                    }

                    if (nb_product === 'all') {
                        $('.see_all').html('');
                    }

                    /**
                 * Remove all clone price in express buy BTN
                 */
                $('.wrapperProdVertical .w-submit-button').find('.item_price').remove();
                /**
                * Clone price in express buy BTN (Rayon voir plus)
                */
                $('.wrapperProdVertical').each(function () {
                    var productPrice = $(this).find('.item_price');
                    var productButton = $(this).find('.w-submit-button');
                    var is_precommande = $(this).find('.w-submit-button').attr('data-is-preco');
                    var text_precommande = $(this).find('.w-submit-button span');

                    if (is_precommande !== undefined ) {
                        text_precommande.text(Translator.translate('add_basket_preco'));
                    } else {
                        productPrice.clone().appendTo(productButton);
                    }
                });
                    

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) {
                        }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) {
                        }
                    }

                    /** END LEGACY **/
                }
            },
            complete: function () {

                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                if (!fromScrollBottom && !fromScrollTop) {
                    //reset initiialPage if from filter
                    $("#initial_page").trigger("change");
                }

                /**
                 * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
                 */
                var total_element = document.getElementById('totalElems').value;
                var productLoaded = $('.item:not(".push")').length;
                var percentLoaded = (productLoaded * 100 / total_element);

                /***@ check if product loaded in the page is more then total elements */
                if (productLoaded > output.nb_tot_prod) {
                    $('.nbProdDisplayed').html(productLoaded + ' ' + Translator.translate('pagerTxt') + ' ' + productLoaded);
                } else {
                    $('.nbProdDisplayed').html(productLoaded + ' ' + Translator.translate('pagerTxt') + ' ' + parseInt(output.nb_tot_prod));
                }

                $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

                $('.loader_scroll').removeClass('loading');

                if (productLoaded >= total_element) {
                    $('.loader_scroll').hide();
                } else {
                    $('.loader_scroll').show();
                }
            }
        });
    }
}

function updateFilter(
    tab_response,
    page,
    parentPath,
    type,
    idObj,
    no_price_range_update
) {
    if (tab_response[0] != "") {
        var bloc_photo = tab_response[0];
        document.getElementById("list_item").innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function () {
            $(this).hover(
                function () {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .show();
                },
                function () {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .hide();
                }
            );
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];
        var nb_prod = tab_response[4];

        if (document.getElementById("nb_prod_numeric"))
            document.getElementById("nb_prod_numeric").innerHTML = nb_prod;
        if (document.getElementById("label_nb_prod_numeric")) {
            if (nb_prod == "")
                document.getElementById("label_nb_prod_numeric").style.display =
                    "none";
            else
                document.getElementById("label_nb_prod_numeric").style.display =
                    "block";
        }

        if (typeof page == "undefined") {
            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update) {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);
                $("#slider-range").slider("option", {
                    min: parseInt(prix_min),
                    max: parseInt(prix_max),
                    values: [parseInt(prix_min), parseInt(prix_max)],
                });
                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .next()
                    .css("left", "100%");
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split("~");
            var cnt_color = tab_color_tmp.length;

            if (tab_color_tmp[0] != "") {
                txt_color = Translator.translate('recherche_couleurs');
                var bloc_color =
                    '<div class="select_recherche">' +
                    txt_color +
                    '</div><div class="selected_options select_colors"></div>';

                var str_color = "";

                for (var j = 0; j < cnt_color; j++) {
                    var detail_color = tab_color_tmp[j].split("|");
                    var class_color = "btn_select_off";

                    if (tab_color.length > 0) {
                        if (in_array_js(detail_color[0], tab_color)) {
                            class_color = "btn_select";
                        }
                    }

                    str_color +=
                        '<div id="couleur_' +
                        detail_color[0] +
                        '" class="left_menu_obj ' +
                        class_color +
                        " couleur_" +
                        detail_color[0] +
                        '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' +
                        detail_color[1] +
                        "</div></div>";
                }

                $("#bloc_couleur")
                    .show()
                    .find(".selected_options")
                    .html(str_color);
            } else {
                $("#bloc_couleur")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }

            // size
            var size = tab_response[8];
            var tab_size_tmp = size.split("~");
            var cnt_size = tab_size_tmp.length;

            if (tab_size_tmp[0] != "") {
                txt_size = Translator.translate('recherche_taille');

                var bloc_size =
                    '<div class="select_recherche">' +
                    txt_size +
                    '</div><div class="selected_options"></div>';

                var str_size = "";

                for (var j = 0; j < cnt_size; j++) {
                    var detail_size = tab_size_tmp[j].split("|");
                    var class_size = "btn_select_off";

                    if (tab_taille.length > 0) {
                        if (in_array_js(detail_size[0], tab_taille))
                            class_size = "btn_select";
                    }

                    str_size +=
                        '<div id="taille_' +
                        detail_size[0] +
                        '" class="left_menu_obj ' +
                        class_size +
                        '"><div class="f_left"></div><div class="f_left txt_menu">' +
                        detail_size[1] +
                        "</div></div>";
                }

                $("#bloc_taille")
                    .show()
                    .find(".selected_options")
                    .html(str_size);

                var windowW = $(window).height();

                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }
            } else {
                $("#bloc_taille")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
        }
    } else {
        $("#list_item").html(Translator.translate('no_response_search'));
        $("#pagination_haut").html("");
        $("#pagination_bas").html("");
    }

    $(".left_menu_obj").on("click touchend", filterBoxesToggle);

    var windowW = $(window).height();

    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }

    $("#page").val(0);
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases � cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById("filter_sticky"),
        output = {},
        is_from_page = false,
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function (elm) {
        if (elm.type === "checkbox" || elm.type === "radio") {
            if (elm.checked && elm.value !== "") {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === "hidden") {
            if (elm.value !== "") {
                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join("~");
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;
        is_from_page = true;

    }

    // Add misc values to output object
    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;
    output.keyword = form.keyword.value;
    output.nb_prod = form.nb_prod.value;
    output.nb_prod_default = form.nb_prod_default.value;
    output.totalElems = form.totalElems.value;

    // Si le prix existe
    if ($("#price_min").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (bloc_search_xhr) {
        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + "ajax_reload_search.php",
        type: "get",
        data: output,
        success: function (res) {
            var old_elm = document.getElementById("scroll_items"),
                content,
                itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                btn.nextElementSibling.classList.contains("btn_tunnel")
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split("~");

            itm_length = parseFloat(res[1]);

            if (content !== "") {
                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                old_elm.innerHTML = "";

                document.getElementById("nb_articles").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }

                /**
                 * Remove all clone price in express buy BTN
                 */
                $('.wrapperProdVertical .w-submit-button').find('.item_price').remove();
                /**
                * Clone price in express buy BTN (Rayon voir plus)
                */
                $('.wrapperProdVertical').each(function () {
                    var productPrice = $(this).find('.item_price');
                    var productButton = $(this).find('.w-submit-button');
                    var is_precommande = $(this).find('.w-submit-button').attr('data-is-preco');
                    var text_precommande = $(this).find('.w-submit-button span');

                    if (is_precommande !== undefined ) {
                        text_precommande.text(Translator.translate('add_basket_preco'));
                    } else {
                        productPrice.clone().appendTo(productButton);
                    }
                });

                // LEGACY
                var js_compteur = $(".vf_countdown").length;
                var js_encart = content.match(
                    /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                );

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(
                                js_compteur[i].replace(
                                    /(vf_compteur_begin|vf_compteur_end)/g,
                                    ""
                                )
                            );
                        }
                    } catch (e) {
                    }
                }

                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(
                            /(\/\/vf_begin|\/\/vf_end)/g,
                            ""
                        );
                        eval(js_encart);
                    } catch (e) {
                    }
                }
                // END LEGACY
            }
        },
        complete: function () {
            //force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }
        },
    });
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {
    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {
        str_year = '&year=' + year;
    }

    if (reload !== undefined) {
        str_reload = '&reload=true';
    }

    // Gestion de la surbrillance des onglets
    var i = 1;
    while (document.getElementById("onglet_" + i + "_mid")) {
        if (type == i) {
            $("#onglet_" + i + "_left").attr("class", "left-on");
            $("#onglet_" + i + "_mid").attr("class", "mid-on");
            $("#onglet_" + i + "_right").attr("class", "right-on");
        } else {
            $("#onglet_" + i + "_left").attr("class", "left-off");
            $("#onglet_" + i + "_mid").attr("class", "mid-off");
            $("#onglet_" + i + "_right").attr("class", "right-off");
        }
        i++;
    }

    var container = document.getElementById(elementID);

    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {
                $(container).html(response);
            }
            return true;
        } else {
            container.innerHTML = '';
            return false;
        }
    } else
        return false;
}

// Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
        elem.text(msg_open);

    } else {
        messageContainer.addClass("appear");
        elem.text(msg_close);
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message").not(elem).removeClass("actif");
        $(".btn_ouvrir_message").not(elem).text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $(".btn_ouvrir_message").not(elem).text(msg_open);
            $.ajax({
                type: "post",
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link("message_detail"),
                success: function (response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else {
            $("#msg_" + mId).slideDown();
            elem.addClass("actif");
            elem.text(msg_close);
        }
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}

function show_message(mId, hide) {
    if (hide == 0) {
        var ajax_content = ajax_file("ajax_show_messages.php?mId=" + mId);

        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;
            document.getElementById("message_" + mId).onclick = "";
            document.getElementById("message_" + mId).style.cursor = "default";
        }
    } else {
        var ajax_content = ajax_file("ajax_show_messages.php?mId=" + mId + "&hide=1");

        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;
            document.getElementById("message_" + mId).onclick = "show_message(" + mId + ",0);";
            document.getElementById("message_" + mId).style.cursor = "pointer";
        }
    }
}

var timer;

function newNewsletterFromCmpid() {
    var flag = verif_mail("email_inscription_home", "login_email");

    if (flag) {
        flag = ajax_file(
            path_relative_root +
            "ajax_email_newsletter_exist.php?mail=" +
            document.getElementById("email_inscription_home").value
        );

        var elementID = "email_inscription_home";
        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + "Label");
        var erreurText = document.getElementById("erreur_msg");

        if (flag === "1") {
            flag = false;
        } else {
            flag = true;
        }

        if (!flag) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' + Translator.translate('login_email') + " :</p>" + Translator.translate('mail_newsletter_exist');
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
        } else {
            objElement.className = "inputForm";
            objElement.style.border = "1px solid #CCCCCC";
            objLabel.className = "labelForm";
        }
    }

    if (flag) {
        clearTimeout(timer);
        document.getElementById("bloc_erreur").style.display = "none";
        document.getElementById("img_error_popup").style.display = "none";
    } else {
        var val = document.getElementById("erreur_msg").innerHTML;
        var elem_img = document.getElementById("img_error_popup");

        $("#img_error_popup").css("display", "inline-block");
        elem_img.setAttribute("onmouseover", function () {
            OverPopup(this, val, "visible", "cmpid_adwords");
        });
        elem_img.onmouseover = function () {
            OverPopup(this, val, "visible", "cmpid_adwords");
        };
        elem_img.setAttribute("onmouseout", function () {
            OverPopup(this, val, "none", "cmpid_adwords");
        });
        elem_img.onmouseout = function () {
            OverPopup(this, val, "none", "cmpid_adwords");
        };
        elem = document.getElementById("email_inscription_home");
        elem.style.border = "1px solid red";
        pos = getPos(elem_img);
        document.getElementById("bloc_erreur").style.position = "absolute";

        var nav = navigator.appVersion;

        if (!/*@cc_on!@*/ (false || !!document.documentMode)) {
            $("#bloc_erreur").fadeIn(400);
        } else {
            document.getElementById("bloc_erreur").style.display = "block";
        }

        var clone = $("#bloc_erreur").clone(),
            c_pos = {
                top:
                    $("#img_error_popup").offset().top -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().top -
                    $("#bloc_erreur").height() +
                    4,
                left:
                    $("#img_error_popup").offset().left -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().left -
                    $("#bloc_erreur").innerWidth() +
                    $("#img_error_popup").width() * 2 +
                    6,
            };

        $("#bloc_erreur").remove();
        $("#img_error_popup").after(clone);

        $("#bloc_erreur")
            .find("td:nth-child(2)")
            .children("img")
            .css({
                float: "right",
                margin: "0 7px",
            });

        $("#bloc_erreur").css({
            position: "absolute",
            top: c_pos.top,
            left: c_pos.left + 271,
            "z-index": "99999",
            opacity: "1",
        });

        window.clearTimeout(timer);
        timer = setTimeout("closeInfobulle('bloc_erreur')", 5000);
    }

    return flag;
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + "ajax_deconnect.php");

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

// wishlist
function addToWishlistShoppingcart() {
    $.post(path_relative_root + "ajax_cart_to_wishlist.php", function (resp) {
        $(".btn_top_wishlist .gris").html(
            "(x" + resp.result.wishlist_size + ")"
        );
        $("#addToWishlistShoppingcart")
            .removeClass("addToWishlistShoppingcart")
            .addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").html(resp.result.button_label);
        $("#addToWishlistShoppingcart")
            .attr("onclick", "")
            .attr(
                "href",
                path_relative_root + create_link("client_index") + "#wl"
            );
    });
}

function deletePromoCode() {
    $.ajax({
        type: "get",
        url: path_relative_root + create_link("delete_promo_code"),
        success: function (response) {
            location.href = path_relative_root + create_link("order_kiala");
        },
    });
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte, type) {
    var isService = (type == 'service') ? true : false;
    $.ajax({
        type: "post",
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
            is_service : isService
        },
        url: path_relative_root + "ajax_removeBasketProd.php",
        success: function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");
                return;
            }

            $("#cart_top").html(res.datas.html);
            $('#show_top_cart.ajax_rollAccountEntries').addClass('show');

            var count_bask = $('#cart_top .cart').attr('data-cart');

            if (count_bask > 2) {
                $(".cart_product_listing").overlayScrollbars({});
            }

            if (count_bask === undefined) {
                var basket_empty = '<div class="no_product">' + Translator.translate('basket_empty') + '</div>';
                $('#cart_top .noprod').append(basket_empty);
            }

        },
    });
}

function sendMessage(id) {
    //disable the default form submission
    var mId = id;
    $(".loader_reponse_contact").css("display", "block");
    $("#envoyer_rep").addClass("active");

    if ($("#msg_txt_" + id).val() != "") {
        var upload_fail = Translator.translate('contact_msg_pj_fail');
        var formData = new FormData($("form#data")[0]);
        $.ajax({
            url: path_relative_root + create_link("message_detail"),
            type: "POST",
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function (response) {
                if (response == "1") {
                    $("#msg_conf_" + id).html(Translator.translate('contact_msg_envoyer'));
                    $("#msg_conf_" + id).show();
                    $("#msg_conf_" + id).addClass("active");
                    $("#msg_txt_" + id).val(" ");
                    clearInput("filepj1");
                    $(".loader_reponse_contact").css("display", "none");
                    $("#envoyer_rep").addClass("active");
                    return false;
                } else if (response == "3") {
                    clearInput("filepj1");
                } else {
                    $("#msg_conf_" + mId).html(Translator.translate('contact_msg_fail'));
                    $("#msg_conf_" + mId).show();
                }
            },
        });
    } else {
        $(".loader_reponse_contact").css("display", "none");
        $("#contact_msg_text_vide")
            .css("color", "red")
            .fadeIn();
        setTimeout(function () {
            formMessageReset(id);
        }, 5000);
        $("#msg_conf_" + mId).hide();
    }
}

function ajax_achat_express(reference, wishlist = false, idprod, code_couleur) {
    var arg = '';

    if (wishlist == true) {
        arg += '&wishlist';
    }

    $.ajax({
        type: 'get',
        url: path_relative_root + create_link('ajax_achat_express') + "?ref=" + reference + '&couleur=' + code_couleur + arg,
        success: function (response) {
            if (!$('#lightbox_achat_express').length) {
                $('body').append('<div id="lightbox_achat_express" class="lightbox"></div>');
            }
            $('#lightbox_achat_express').html('<div id="shad_sizeguide" class="shad"></div><div class="express_response"><span class="bgSprite bfr cross_blue close_pop" onclick="closeMultiShad(\'lightbox_achat_express\')"></span>' + response + '</div>').appendTo('body');
            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);

            $(".productSizeFieldset").on("click touch", function () {
                var sizesList = $(".sizesList");
                var shadSelect = $(".shadSelect");

                sizesList.toggleClass("opened");
                $(this).toggleClass("actif");
                shadSelect.addClass("actif");

                shadSelect.on("click touch", function () {
                    sizesList.removeClass("opened");
                    $(this).removeClass("actif");
                    $(".productSizeFieldset").removeClass("actif");
                });
            });
        }
    });
}

function process_type_preco() {
    var val = $(".bloc_lightbox_preco").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + create_link('ajax_choose_preco'),
        success: function (response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }
                var index_conveyor = typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function (i, item) {
                    if (typeof(array_transporteurs_datas[i]) != 'undefined') {
                        array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                        array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                        array_transporteurs_datas[i]["fdp"]["format"] = item["format"];
                        $(".choix_delivery[data-id-transporteur=" + i + "] .pricetag").html(item["format"]);
                        $(".choix_delivery[data-id-transporteur=" + i + "] .price_tag").html(item["format"]);
                    }
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr("checked", "checked");
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr("checked", "checked");
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");

                $("#main_cart_wrapper > div.left_side.elem_tunnel > div > div.choix_delivery > div.right_delivery > span").trigger("click");
            }
        },
    });
}

function viewDefault() {
    if ($('#list_item').length && $('#list_item').hasClass('viewByThree')) {
        $('#list_item').removeClass('viewByThree');
        $('#list_item').addClass('viewDefault');
        $('#viewChanger .viewByThree').removeClass('active');
        $('#viewChanger .viewDefault').addClass('active');
        createCookie("nb_produits", 4, 1);
    }
}

function viewByThree() {
    if ($('#list_item').length && $('#list_item').hasClass('viewDefault')) {
        $('#list_item').removeClass('viewDefault');
        $('#list_item').addClass('viewByThree');
        $('#viewChanger .viewByThree').addClass('active');
        $('#viewChanger .viewDefault').removeClass('active');
        createCookie("nb_produits", 3, 1);
    }
}

function filterTrigger() {
    if ($('.filters_products').length) {
        var filterTitle = $('#trigger_filtre');
        var filterWrapper = $('#filter_sticky .filters_container');

        if (filterWrapper.hasClass('isOpen')) {
            filterTitle.removeClass('active');
            filterWrapper.removeClass('isOpen');
            filterWrapper.slideUp();
            $("#shad_filter").removeClass("actif");

        } else {
            filterTitle.addClass('active');
            filterWrapper.addClass('isOpen');
            filterWrapper.slideDown();
            $("#shad_filter").addClass("actif");
        }
    }
}

function close_filter() {
    $(".close_filter, #shad_filter").on("click", function () {
        $(".filters_container").removeClass("isOpen");
        $("#trigger_filtre").removeClass("active");
        $("#shad_filter").removeClass("actif");
    });
}

var tempo_reset = false;
var price_min = $("#price_min").val();
var price_max = $("#price_max").val();
var currency = $("#price_min").data('currency');

function initAllFilters() {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {
        if ((elm.type === 'checkbox' || elm.type === 'radio')) {
            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.value = 0;
                elm.checked = false;
            }
        } else {
            // Reset range slider properties
            if (elm.name === 'initial_min_price') {
                reInitRngSlider();
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $('label.is_checked').removeClass('is_checked');
    $('input#choice_tri').val(0);

    // Close all filters
    $('.holder_filters .selected_options').removeClass('actif').slideUp(400);
    $('.trigger_dropdown').removeClass('actif');

    var min_price = $("#amount1");
    var max_price = $("#amount2");

    min_price.val(price_min);
    max_price.val(price_max);

    $("#prix_min_bloc label").html(min_price.val() + '&nbsp;' + currency);
    $("#prix_max_bloc label").html(max_price.val() + '&nbsp;' + currency);

    $(".ui-slider-range").width("100%")
        .css('left','0%')
        .next().css('left','0%')
        .next().next().css('left','100%');

    $('.count_filters').empty();

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);

}

function remove_product_from_wishlist(id, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), { id: id, remove: true }, callback);
}

function ajax_RemoveWishlistProd(id) {
    remove_product_from_wishlist(id, function(data) {
        if (data.result) {
            var wishlistLength = data.result.wishlistLength;

            addToWishlistUpdateHeader(wishlistLength);

            $('.remove_top_wishlist[data-id=' + id + ']').parents('.product-link').remove();
            if (wishlistLength == 0) {
                $('#show_top_wish #cart_list, #show_top_wish .wrap_tot_panier .cart_panier_total, #show_top_wish .wrap_tot_panier .cart_buttons').remove();
                $('<p class="ajax_wishlist_empty">' + Translator.translate('your_wishlist_empty') + '</p>').insertAfter('#show_top_wish .rollover_title');
                $('.wrap_tot_panier').remove();
            } else {
                $('#show_top_wish .cart_panier_total .pricetag').html(data.result.wishlistSum);
            }

            if ($('#show_top_wish .prod_reassurance_swiper').length) {
                setTimeout(function () {
                    var rolloverWishReassuranceSwiper = new Swiper('#show_top_wish .prod_reassurance_swiper', {
                        // slidesPerView: 2.5,
                        slidesPerView: 'auto',
                        loop: true,
                        speed: 4000,
                        freeMode: true,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false,
                        },
                    });
                }, 500);
            }
        }
    });
}

$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();
    selectedProducts = [];
    $(".remove_top_wishlist").each(function(index) {
        var id = $(this).attr("data-id");
        selectedProducts.push(id);
    });
    moveProductFromWishlistToCart(selectedProducts);
});

function moveProductFromWishlistToCart(array_wishlist_product_ids) {
    $.post(path_relative_root + "ajax_add_panier_wishlist.php", {data: array_wishlist_product_ids.join(",")}, function(resp) {
        var data_layer_products = window.data_layer_products;
        var dtl_products = [];
        if (resp == "ok") {
            if (data_layer_products != undefined && Object.keys(data_layer_products).length > 0) {
                for (const property in data_layer_products) {
                    if (array_wishlist_product_ids.includes(parseInt(property))) {
                        dtl_products.push(data_layer_products[property])
                    }
                }
            }

            if (dtl_products.length > 0) {
                window.dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        'currencyCode' : siteCurrency.symbol_right,
                        "add": {
                            "products": dtl_products
                        }
                    }
                })
            }
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

$(document).ready(function () {
    // View x4
    if (readCookie("nb_produits") == 4) {
        if ($('#list_item').length && $('#list_item').hasClass('bydefault')) {
            $('#list_item')
                .removeClass('bydefault')
                .addClass('byFour');
            $('#viewChanger .default').removeClass('actif');
            $('#viewChanger .byFour').addClass('actif');
        }

    } else {
        // Default view x3
        if ($('#list_item').length && $('#list_item').hasClass('byFour')) {
            $('#list_item')
                .removeClass('byFour')
                .addClass('bydefault');
            $('#viewChanger .byFour').removeClass('actif');
            $('#viewChanger .default').addClass('actif');
        }
    }
});

function display_lightbox_specifique() {
    var html = ajax_file(path_relative_root + 'ajax_lightbox_produit_specifique.php');

    if (html) {
        var $lighbox = $('#lightbox_achat_express');
        var $shad = $('#shad');
        $lighbox.html('<div style="position: relative">' + html + '</div>').appendTo('body');

        // tooltip events
        eventButton();
        setTimeout(function () {
            openMultiShad('lightbox_achat_express');
            if (!$(".w-alertestock-form").length) {
                new Vue({components: {wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
            }
            checkSubmitState();
        }, 500);
    }
}

function reInitRngSlider() {
    var init_min_price = $('input[name="initial_min_price"]').val();
    var init_max_price = $('input[name="initial_max_price"]').val();

    $('#amount1').val(init_min_price);
    $('#amount2').val(init_max_price);

    // Reinit slider values and position
    $('#slider-range').slider("option", "values", [init_min_price, init_max_price]);
    $('#slider-range .ui-slider-handle:first .handler_price').html(init_min_price);
    $('#slider-range .ui-slider-handle:last .handler_price').html(init_max_price);
}

function renewOrder(elem, orderId) {
    elem.classList.add('inactive');

    $.ajax({
        type: 'post',
        url: path_relative_root + create_link('ajax_get_baskets_from_order'),
        data: {
            orderId: orderId,
        },
        success: function (response) {
            let data = JSON.parse(response);

            var reqPromises = data.map(function (basket) {
                return $.get({
                    url: path_relative_root + create_link('ajax_update_basket'),
                    data: {
                        idprod: basket.produit_id,
                        panierid: 0,
                        qte: basket.panier_qte,
                        idcouleur: basket.produit_couleur,
                        idtaille: basket.produit_taile,
                        renewOrder: true,
                        dataTypeReturn: 'json',
                    },
                });
            });

            Promise.all(reqPromises).then(function (results) {
                let errorOutOfStock, productAdded = false;
                results.forEach((res) => {
                    let data = JSON.parse(res);
                    if (!data.error) {
                        productAdded = true;
                    } else if (data.error && data.error.code == 1) {
                        errorOutOfStock = true;
                    }
                });

                if (errorOutOfStock || results.length == 0) {
                    $('.order_error').remove();
                    $('tr[data-order=' + orderId + ']').append('<span class="order_error">' + Translator.translate('renew_order_out_of_stock') + '</span>');
                    $('tr[data-order=' + orderId + '] td').css('padding-bottom', '50px');
                    $('.cmd_items').append('<span class="order_error">' + Translator.translate('renew_order_out_of_stock') + '</span>');
                }

                if (productAdded) {
                    var response_basket = ajax_file('ajax_show_basket.php');

                    $('#cart_top').html(response_basket);

                    var count_bask = $('#cart_top .cart').attr('data-cart');

                    if (count_bask > 2) {
                        $('#cart_list').overlayScrollbars({});
                    }

                    newshowmodal();
                }

                elem.classList.remove('inactive');
            });
        },
    });
}